@import url("assets/Inter/Inter.css");

body {
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

*:focus {
  outline: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

.bgPrimary {
  background: #323e54 !important;
}

@media print {
  .no-print {
    display: none;
  }
}
